import { toRaw } from 'vue';

import i18n from '@/i18n';
import router from '@/router';
import useUserStore from '@/stores/user.js';
import { initialSettings } from '@/utils/header/initialSettings.js';

export function initHeader() {
  if (window.Header) {
    window.Header.init(initialSettings, import.meta.env.VITE_INTRANET_URL);
    setLanguage(window.Header.getLanguage());
    window.Header.setActiveNavigation('iam');
  }
}

export function setLanguage(language) {
  let newLanguage = 'en';
  if (i18n.global.availableLocales.indexOf(language) !== -1) {
    newLanguage = language;
  }
  i18n.global.locale.value = newLanguage;
  document.documentElement.setAttribute('lang', newLanguage.split('_')[0]);
  window.Header.setLanguage(language);
  return newLanguage;
}

export function setHeaderParams() {
  const userStore = useUserStore();

  if (userStore.header !== undefined) {
    const user = {
      user: {
        id: userStore.user.id,
        username: userStore.user?.username,
        first_name: userStore.user.first_name,
        last_name: userStore.user.last_name,
        avatar_url: `${userStore.user.avatar || ''}`,
        logout: localizeObject({
          key: 'logout',
          title: 'Log out',
          url: '/logout',
        }),
        account: localizeObject({
          key: 'account',
          title: 'Account',
        }),
        account_protection: undefined,
        settings: {
          linkTitle: i18n.global.t('headerLocale.userSettings'),
        },
      },
    };

    if (userStore?.user && userStore.header?.shows) {
      userStore.header.shows.impersonation =
        !!userStore.user?.view_as_mode_enabled;
    }

    let headerSettings = {
      ...toRaw(userStore.header),
      ...localeHeader(),
      ...user,
    };

    window.Header.setSettings(headerSettings);
    // this.enableGTag();
  }
}

export function localizeObject(obj) {
  if (i18n.global.locale.value !== 'en_US') {
    localizeObjectDeep(obj);
  }
  return obj;
}

export function localeHeader() {
  const userStore = useUserStore();
  let localeHeader = {};

  if (userStore.header?.shows.dashboard) {
    localeHeader.dashboard = userStore.header.dashboard = {
      myRequestBtn: i18n.global.t('headerLocale.dashboard.myRequest'),
      tasksToApproveBtn: i18n.global.t('headerLocale.dashboard.tasksToApprove'),
      vacationsTitle: i18n.global.t('headerLocale.dashboard.vacations'),
      conferencesTitle: i18n.global.t('headerLocale.dashboard.conferences'),
      businessTripsTitle: i18n.global.t('headerLocale.dashboard.businessTrips'),
      businessTripsConfTitle: i18n.global.t(
        'headerLocale.dashboard.businessTripsConf'
      ),
      probationsTitle: i18n.global.t('headerLocale.dashboard.probations'),
      compensationTitle: i18n.global.t('headerLocale.dashboard.compensations'),
      conferencesNotFound: i18n.global.t(
        'headerLocale.dashboard.conferencesNotFound'
      ),
    };
  }

  if (userStore.header?.shows.search) {
    localeHeader.search = userStore.header.search = {
      ...userStore.header.search,
      content: {
        placeholder: i18n.global.t('headerLocale.search.headerPlaceholder'),
        linkToSearch: i18n.global.t('headerLocale.search.linkToSearch'),
        benefit: i18n.global.t('headerLocale.search.titles.benefit'),
        post: i18n.global.t('headerLocale.search.titles.post'),
        user: i18n.global.t('headerLocale.search.titles.user'),
        blog: i18n.global.t('headerLocale.search.titles.blog'),
        marketing_activity: i18n.global.t(
          'headerLocale.search.titles.marketing_activity'
        ),
        user_fuzzy: i18n.global.t('headerLocale.search.titles.user_fuzzy'),
        section: i18n.global.t('headerLocale.search.titles.section'),
      },
    };
  }

  if (i18n.global.locale.value !== 'en_US') {
    ['sections', 'idea'].forEach(attr => {
      localeHeader[attr] = localizeObject(userStore.header[attr]);
    });
    return localeHeader;
  }
  return toRaw(userStore.header);
}

export function localizeObjectDeep(obj) {
  if (obj !== null && typeof obj === 'object') {
    if (
      Object.keys(obj).includes('key') &&
      Object.keys(obj).includes('title')
    ) {
      obj.title = i18n.global.t(`headerLocale.${obj.key}`);
    }
    Object.keys(obj).forEach(key => {
      if (obj[key] !== null) {
        if (typeof obj[key] === 'object') {
          localizeObjectDeep(obj[key]);
        }
        if (Array.isArray(obj[key])) {
          obj[key].forEach(obj => {
            localizeObjectDeep(obj);
          });
        }
      }
    });
  }
}

window.redirectMenu = function (obj) {
  if (obj && obj.key && obj.url && obj.event) {
    obj.url = obj.url.split(window.location.host).pop();
    // window.Header.setActive(obj.key);
    router.push(obj.url);
    obj.event.preventDefault();
    return false;
  }
  return true;
};
