export default function (instance) {
  const urlPrefix = '/trips';
  const forArray = {
    paramsSerializer: {
      indexes: null,
    },
  };

  return {
    create: async function (data) {
      return await instance.post(`${urlPrefix}/`, data);
    },
    getList: async function (params) {
      return await instance.get(`${urlPrefix}/`, {
        params,
        ...forArray,
      });
    },
    getProblemList: async function (params) {
      return await instance.get(`${urlPrefix}/incorrect_chain/`, {
        params,
        ...forArray,
      });
    },
    getArchivedPersonalList: async function (params) {
      return await instance.get(`${urlPrefix}/my/archived/`, {
        params,
        ...forArray,
      });
    },
    getPersonalList: async function (params) {
      return await instance.get(`${urlPrefix}/my/`, { params });
    },
    getPersonalProblemList: async function (params) {
      return await instance.get(`${urlPrefix}/my/incorrect_chain/`, {
        params,
        ...forArray,
      });
    },
    getInfo: async function (params) {
      return await instance.get(`${urlPrefix}/prepare`);
    },
    getTripById: async function (id) {
      return await instance.get(`${urlPrefix}/${id}`);
    },
    editById: async function ({ id, data }) {
      return await instance.put(`${urlPrefix}/${id}`, data);
    },
    saveTickets: async function ({ id, data }) {
      return await instance.post(`${urlPrefix}/${id}/tickets/`, data);
    },
    getCounters: async function () {
      return await instance.get(`${urlPrefix}/counters`);
    },
    getPersonalCounters: async function () {
      return await instance.get(`${urlPrefix}/my/counters`);
    },
    approveTasks: async function (ids) {
      return await instance.post(`${urlPrefix}/approve`, {
        ids: ids,
      });
    },
    rejectTasks: async function (ids) {
      return await instance.post(`${urlPrefix}/decline`, { ids: ids });
    },
    approveTaskById: async function (id, comment, action) {
      return await instance.post(`${urlPrefix}/${id}/approve`, {
        comment: comment,
        action: action,
      });
    },
    rejectTaskById: async function (id, comment) {
      return await instance.post(`${urlPrefix}/${id}/decline`, {
        comment: comment,
      });
    },
    getHostsFilter: async function (params) {
      return await instance.get(`${urlPrefix}/filters/hosts`, { params });
    },
    getStatusesFilter: async function (params) {
      return await instance.get(`${urlPrefix}/filters/statuses`, { params });
    },
    getCountriesFilter: async function (params) {
      return await instance.get(`${urlPrefix}/filters/countries`, { params });
    },
    getBusyPeriods: async function (params) {
      return await instance.get(`${urlPrefix}/busy_periods`, {
        params,
        ...forArray,
      });
    },
    getComments: async function (idTrip) {
      return await instance.get(`${urlPrefix}/${idTrip}/comments/`);
    },
    addComment: async function ({ idTask, params }) {
      return await instance.post(`${urlPrefix}/${idTask}/comments/`, params);
    },
    editComment: async function ({ idTask, idComment, params }) {
      return await instance.put(
        `${urlPrefix}/${idTask}/comments/${idComment}`,
        params
      );
    },
    removeComment: async function ({ idTask, idComment }) {
      return await instance.delete(
        `${urlPrefix}/${idTask}/comments/${idComment}`
      );
    },
    getDailyAllowanceList: async function (params) {
      return await instance.get(`${urlPrefix}/daily_allowance/`, {
        params,
      });
    },
    getLegalEntity: async function ({
      limit = 100,
      offset = 0,
      is_archive = false,
    }) {
      return await instance.get(`${urlPrefix}/filters/legal_entities`, {
        params: { limit, offset, is_archive },
      });
    },
    getArea: async function ({ limit = 100, offset = 0, is_archive = false }) {
      return await instance.get(`${urlPrefix}/filters/areas`, {
        params: { limit, offset, is_archive },
      });
    },
  };
}
