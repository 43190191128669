export default function (instance) {
  const urlPrefix = '/vacation';

  return {
    create: async function (data) {
      return await instance.post(`${urlPrefix}/`, data);
    },
    getInfo: async function () {
      return await instance.get(`${urlPrefix}/info`);
    },
    getList: async function (params) {
      return await instance.get(`${urlPrefix}/`, {
        params: params,
        paramsSerializer: {
          indexes: null,
        },
      });
    },
    getHolidaysByYear: async function (year) {
      return await instance.get(`${urlPrefix}/calendar/${year}/holidays`);
    },
    getCalendar: async function (year) {
      return await instance.get(`${urlPrefix}/calendar/${year}`);
    },
    checkValidDates: async function (data) {
      return await instance.post(`${urlPrefix}/check_dates`, data);
    },
    getAvailablePeriods: async function (params) {
      return await instance.get(`${urlPrefix}/available_periods`, {
        params: params,
      });
    },
    getVacation: async function ({ id, typeInterface }) {
      return await instance.get(`${urlPrefix}/${id}`, {
        params: {
          interface_type: typeInterface,
        },
      });
    },
    setStatus: async function ({ id, params }) {
      return await instance.post(`${urlPrefix}/${id}/status`, params);
    },
    getApproversList: async function ({
      limit = 100,
      offset = 0,
      search = '',
      is_archive = false,
    }) {
      return await instance.get(`${urlPrefix}/filters/approvers`, {
        params: { limit, offset, search, is_archive },
      });
    },
    getAuthorsList: async function ({
      limit = 100,
      offset = 0,
      search = '',
      is_archive = false,
    }) {
      return await instance.get(`${urlPrefix}/filters/authors`, {
        params: { limit, offset, search, is_archive },
      });
    },
    getArea: async function ({ limit = 100, offset = 0, is_archive = false }) {
      return await instance.get(`${urlPrefix}/filters/areas`, {
        params: { limit, offset, is_archive },
      });
    },
    getLegalEntity: async function ({
      limit = 100,
      offset = 0,
      is_archive = false,
    }) {
      return await instance.get(`${urlPrefix}/filters/legal_entities`, {
        params: { limit, offset, is_archive },
      });
    },
    getIdsVacation: async function ({
      limit = 100,
      offset = 0,
      search = '',
      is_archive = false,
    }) {
      return await instance.get(`${urlPrefix}/filters/documents`, {
        params: { limit, offset, search, is_archive },
      });
    },
    getReport: async function () {
      return await instance.get(`${urlPrefix}/vacation_report/`, {
        responseType: 'blob',
      });
    },
    getContractTypes: async function () {
      return await instance.get(`${urlPrefix}/filters/contract_types`);
    },
  };
}
