const adminRoutes = [
  {
    path: '/admin',
    name: 'Admin panel',
    component: () => import('@/views/admin/Index.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        // {
        //   titleKey: 'Sections.AdminPanel',
        //   link: '/admin',
        // },
      ],
    },
  },
  {
    path: '/admin/chain-management',
    name: 'Chain Management List',
    component: () => import('@/views/admin/ChainManagementList.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        // {
        //   titleKey: 'Sections.AdminPanel',
        //   link: '/admin',
        // },
        {
          titleKey: 'Sections.ChainManagement',
          link: '/admin/chain-management',
        },
      ],
    },
  },
  {
    path: '/admin/daily-allowance',
    name: 'Daily Allowance List',
    component: () => import('@/views/admin/DailyAllowanceList.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.DailyAllowance',
          link: '/admin/daily-allowance',
        },
      ],
    },
  },
  {
    path: '/admin/daily-allowance/:id',
    name: 'Daily Allowance Card',
    component: () => import('@/views/admin/DailyAllowanceCard.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.DailyAllowance',
          link: '/admin/daily-allowance',
        },
      ],
    },
  },
];
export default adminRoutes;
