import { execMinDelay } from "@homemg/ui-kit/utils";
import { defineStore } from "pinia";
import { ref } from "vue";

import api from "@/api";
import usePreloaderStore from "@/stores/preloader";
import { setHeaderParams } from "@/utils/header";
import i18n from "@/i18n";

const useUserStore = defineStore('user', () => {
  const user = ref({});
  const header = ref({});

  async function load() {
    const start = new Date();
    const payload = await api.user.self();
    if (payload) {
      if (payload.data?.user) {
        user.value = payload.data.user;

        const preloader = usePreloaderStore();
        execMinDelay(new Date() - start, 1000, () => preloader.hide());
      }

      if (payload.data?.header) {
        header.value = {
          ...payload.data?.header,
          menu: {
            mainSectionTitle: i18n.global.t('headerLocale.mainSectionTitle'),
          },
        };
        setHeaderParams();
      }
    }

    return !!user.value;
  }

  return { user, header, load };
});

export default useUserStore;
