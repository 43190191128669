export default function (instance) {
  const urlPrefix = '/admin/';
  const candidates = urlPrefix + 'chain_management/candidates';

  return {
    getList: async function (params) {
      return await instance.get(`${candidates}`, {
        params,
        paramsSerializer: {
          indexes: null,
        },
      });
    },
    create: async function (params) {
      return await instance.post(`${candidates}`, params);
    },
    delete: async function (id) {
      return await instance.delete(`${candidates}/${id}`);
    },
    edit: async function (id, params) {
      return await instance.put(`${candidates}/${id}`, params);
    },
    loginAsUser: async function (id) {
      return await instance.post(`${urlPrefix}view_as/?user_id=${id}`);
    },
    logoutAsUser: async function (id) {
      return await instance.post(`${urlPrefix}view_as/deactivate`);
    },
    getDailyAllowanceList: async function (params) {
      return await instance.get(`${urlPrefix}daily_allowance/`, {
        params,
      });
    },
    createDailyAllowanceGroup: async function (params) {
      return await instance.post(`${urlPrefix}daily_allowance/`, params);
    },
    editDailyAllowanceGroup: async function (id, params) {
      return await instance.put(`${urlPrefix}daily_allowance/${id}`, params);
    },
    getDailyAllowanceGroup: async function (id) {
      return await instance.get(`${urlPrefix}daily_allowance/${id}`);
    },
    removeDailyAllowanceGroup: async function (id) {
      return await instance.delete(`${urlPrefix}daily_allowance/${id}`);
    },
  };
}
