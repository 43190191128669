import axios from 'axios';

import attachmentApi from '@/api/endpoints/attachment';
import documentsApi from '@/api/endpoints/documents';
import intranetApi from '@/api/endpoints/intranet';
import locationsApi from '@/api/endpoints/locations';
import reservingApi from '@/api/endpoints/reserving';
import serviceApi from '@/api/endpoints/services';
import teamApi from '@/api/endpoints/team';
import tripsApi from '@/api/endpoints/trips';
import userApi from '@/api/endpoints/users';
import vacationApi from '@/api/endpoints/vacation';
import incomeApi from '@/api/endpoints/income';
import adminApi from '@/api/endpoints/admin';
import catalogsApi from '@/api/endpoints/catalogs';
import contractorsApi from '@/api/endpoints/contractors';
import compensationApi from '@/api/endpoints/compensation';
import router from '@/router';
import usePreloaderStore from '@/stores/preloader';

const baseUrl =
  (import.meta.env.MODE === 'development' ||
  import.meta.env.MODE === 'development-headless'
    ? 'https://iam-dev.my.games'
    : '') + '/api/v1';

const instance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

instance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error?.response?.status === 401) {
      const preloader = usePreloaderStore();
      preloader.show();
      router.push({ name: 'Login' });
    }
    if (error?.response?.status === 403) {
      const preloader = usePreloaderStore();
      preloader.show();
      router.push({ name: 'MainPage' });
    }

    return {
      data: error?.response?.data,
      status_code: error?.response?.status,
    };
  }
);

export default {
  reserving: reservingApi(instance),
  user: userApi(instance),
  trips: tripsApi(instance),
  team: teamApi(instance),
  documents: documentsApi(instance),
  locations: locationsApi(instance),
  service: serviceApi(instance),
  vacation: vacationApi(instance),
  intranet: intranetApi(instance),
  income: incomeApi(instance),
  admin: adminApi(instance),
  catalogs: catalogsApi(instance),
  contractors: contractorsApi(instance),
  compensation: compensationApi(instance),
  attachment: attachmentApi(instance),
};
