export default function (instance) {
  const urlPrefix = '/compensation';

  return {
    create: async function (data) {
      return await instance.post(`${urlPrefix}/`, data);
    },
    editTask: async function ({ id, data }) {
      return await instance.put(`${urlPrefix}/${id}`, data);
    },
    getInfo: async function () {
      return await instance.get(`${urlPrefix}/info`);
    },
    getInfoById: async function (id) {
      return await instance.get(`${urlPrefix}/${id}/info`);
    },
    getList: async function (params) {
      return await instance.get(`${urlPrefix}/`, {
        params: params,
        paramsSerializer: {
          indexes: null,
        },
      });
    },
    getTask: async function (id) {
      return await instance.get(`${urlPrefix}/${id}`);
    },
    approveTask: async function (id) {
      return await instance.post(`${urlPrefix}/${id}/approve`);
    },
    rejectTask: async function (id) {
      return await instance.post(`${urlPrefix}/${id}/reject`);
    },
    publishTask: async function (id) {
      return await instance.post(`${urlPrefix}/${id}/publish`);
    },
    sendRevisionTask: async function ({ id, comment }) {
      return await instance.post(`${urlPrefix}/${id}/send_for_revision`, {
        comment,
      });
    },
    removeTask: async function (id) {
      return await instance.delete(`${urlPrefix}/${id}`);
    },
    getArea: async function ({ limit = 100, offset = 0, is_archive = false }) {
      return await instance.get(`${urlPrefix}/filters/areas`, {
        params: { limit, offset, is_archive },
      });
    },
    getLegalEntity: async function ({
      limit = 100,
      offset = 0,
      is_archive = false,
    }) {
      return await instance.get(`${urlPrefix}/filters/legal_entities`, {
        params: { limit, offset, is_archive },
      });
    },
    getUsers: async function ({ limit = 10, offset = 0, search = '' }) {
      return await instance.get(`${urlPrefix}/filters/users`, {
        params: { limit, offset, search },
      });
    },
    getNumbersTask: async function ({ limit = 10, offset = 0, search = '' }) {
      return await instance.get(`${urlPrefix}/filters/document_ids`, {
        params: { limit, offset, search },
      });
    },
    addComment: async function ({ idTask, params }) {
      return await instance.post(`${urlPrefix}/${idTask}/comments/`, params);
    },
    editComment: async function ({ idTask, idComment, params }) {
      return await instance.put(
        `${urlPrefix}/${idTask}/comments/${idComment}`,
        params
      );
    },
    removeComment: async function ({ idTask, idComment }) {
      return await instance.delete(
        `${urlPrefix}/${idTask}/comments/${idComment}`
      );
    },
  };
}
