const vacationsRoutes = [
  {
    path: '/vacations-requests',
    name: 'Personal vacations',
    component: () => import('@/views/vacations/VacationList.vue'),
    meta: {
      usePathKey: true,
      isPersonalVacation: true,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MineVacation',
          link: '/vacations-requests',
        },
      ],
    },
  },
  {
    path: '/vacations-requests/:id',
    name: 'Vacation personal',
    component: () => import('@/views/vacations/VacationCard.vue'),
    meta: {
      isPersonalVacation: true,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MineVacation',
          link: '/vacations-requests',
        },
      ],
    },
  },
  {
    path: '/vacations-requests/create',
    name: 'Vacation create',
    component: () => import('@/views/vacations/VacationCreate.vue'),
    meta: {
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MineVacation',
          link: '/vacations-requests',
        },
        {
          titleKey: 'Vacation.createVacationBC',
          link: '/vacations-requests/create',
        },
      ],
    },
  },
  {
    path: '/vacations-tasks',
    name: 'Requests vacations',
    component: () => import('@/views/vacations/VacationList.vue'),
    meta: {
      usePathKey: true,
      isPersonalVacation: false,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.TasksVacation',
          link: '/vacations-tasks',
        },
      ],
    },
  },
  {
    path: '/vacations-tasks/:id',
    name: 'Vacation requests',
    component: () => import('@/views/vacations/VacationCard.vue'),
    meta: {
      isPersonalVacation: false,
      usePathKey: true,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.TasksVacation',
          link: '/vacations-tasks',
        },
      ],
    },
  },
];
export default vacationsRoutes;
