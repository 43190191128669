export default function (instance) {
  return {
    getAreas: async function ({ limit = 10, offset = 0, search = '' }) {
      return await instance.get(`/areas`, {
        params: { limit, offset, search },
      });
    },
    getLegalEntities: async function ({ limit = 10, offset = 0, search = '' }) {
      return await instance.get(`/legal_entities`, {
        params: { limit, offset, search },
      });
    },
    getDismissalTypes: async function ({
      limit = 100,
      offset = 0,
      search = '',
    }) {
      return await instance.get(`/dismissal_types`, {
        params: { limit, offset, search },
      });
    },
    getDismissalReasons: async function ({
      limit = 100,
      offset = 0,
      search = '',
    }) {
      return await instance.get(`/dismissal_reasons`, {
        params: { limit, offset, search },
      });
    },
    getCurrencies: async function () {
      return await instance.get(`/available_currencies`);
    },
  };
}
