const compensationsRoutes = [
  {
    path: '/compensations-requests',
    name: 'Compensations Requests',
    component: () => import('@/views/compensations/CompensationsList.vue'),
    meta: {
      usePathKey: true,
      isPersonal: true,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MyCompensations',
          link: '/compensations-requests',
        },
      ],
    },
  },
  {
    path: '/compensations-requests/:id',
    name: 'Compensations Request Card',
    component: () => import('@/views/compensations/CompensationsCard.vue'),
    meta: {
      usePathKey: true,
      isPersonal: true,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MyCompensations',
          link: '/compensations-requests',
        },
      ],
    },
  },
  {
    path: '/compensations-tasks',
    name: 'Compensations Tasks',
    component: () => import('@/views/compensations/CompensationsList.vue'),
    meta: {
      usePathKey: true,
      isPersonal: false,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.CompensationsTasks',
          link: '/compensations-tasks',
        },
      ],
    },
  },
  {
    path: '/compensations-tasks/:id',
    name: 'Compensations Task Card',
    component: () => import('@/views/compensations/CompensationsCard.vue'),
    meta: {
      isPersonal: false,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.CompensationsTasks',
          link: '/compensations-tasks',
        },
      ],
    },
  },
];
export default compensationsRoutes;
