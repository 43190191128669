import enums from '@/utils/enums';

const tripsRoutes = [
  {
    path: '/trips-requests',
    name: 'Trips requests',
    component: () => import('@/views/trips/TripsList.vue'),
    meta: {
      usePathKey: true,
      isPersonal: true,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MyTrips',
          link: '/trips-requests',
        },
      ],
    },
  },
  {
    path: '/trips-requests/business-trip/:id',
    name: 'Personal business trip card',
    component: () => import('@/views/trips/TripCard.vue'),
    meta: {
      isPersonal: true,
      type: enums.TYPE_TRIP.BUSINESS_TRIP,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MyTrips',
          link: '/trips-requests',
        },
      ],
    },
  },
  {
    path: '/trips-requests/conferences/:id',
    name: 'Personal conferences card',
    component: () => import('@/views/trips/TripCard.vue'),
    meta: {
      isPersonal: true,
      type: enums.TYPE_TRIP.CONFERENCE,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MyTrips',
          link: '/trips-requests',
        },
      ],
    },
  },
  {
    path: '/trips-requests/business-trip-with-conf/:id',
    name: 'Personal business trip with conf card',
    component: () => import('@/views/trips/TripCard.vue'),
    meta: {
      isPersonal: true,
      type: enums.TYPE_TRIP.BUSINESS_TRIP_CONFERENCE,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.MyTrips',
          link: '/trips-requests',
        },
      ],
    },
  },
  {
    path: '/trips-tasks',
    name: 'Trips tasks',
    component: () => import('@/views/trips/TripsList.vue'),
    meta: {
      isPersonal: false,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.TripsTasks',
          link: '/trips-tasks',
        },
      ],
    },
  },
  {
    path: '/trips-tasks/business-trip',
    name: 'Business trips tasks',
    component: () => import('@/views/trips/TripsList.vue'),
    meta: {
      isPersonal: false,
      type: enums.TYPE_TRIP.BUSINESS_TRIP,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.TripsTasks',
          link: '/trips-tasks',
        },
      ],
    },
  },
  {
    path: '/trips-tasks/conferences',
    name: 'Conferences tasks',
    component: () => import('@/views/trips/TripsList.vue'),
    meta: {
      isPersonal: false,
      type: enums.TYPE_TRIP.CONFERENCE,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.TripsTasks',
          link: '/trips-tasks',
        },
      ],
    },
  },
  {
    path: '/trips-tasks/business-trip-with-conf',
    name: 'Business trips with conf tasks',
    component: () => import('@/views/trips/TripsList.vue'),
    meta: {
      isPersonal: false,
      type: enums.TYPE_TRIP.BUSINESS_TRIP_CONFERENCE,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.TripsTasks',
          link: '/trips-tasks',
        },
      ],
    },
  },
  {
    path: '/trips-tasks/business-trip/:id',
    name: 'Business trip card',
    component: () => import('@/views/trips/TripCard.vue'),
    meta: {
      isPersonal: false,
      type: enums.TYPE_TRIP.BUSINESS_TRIP,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.TripsTasks',
          link: '/trips-tasks',
        },
      ],
    },
  },
  {
    path: '/trips-tasks/conferences/:id',
    name: 'Conferences card',
    component: () => import('@/views/trips/TripCard.vue'),
    meta: {
      isPersonal: false,
      type: enums.TYPE_TRIP.CONFERENCE,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.TripsTasks',
          link: '/trips-tasks',
        },
      ],
    },
  },
  {
    path: '/trips-tasks/business-trip-with-conf/:id',
    name: 'Business trip with conf card',
    component: () => import('@/views/trips/TripCard.vue'),
    meta: {
      isPersonal: false,
      type: enums.TYPE_TRIP.BUSINESS_TRIP_CONFERENCE,
      breadcrumbsHeader: [
        {
          titleKey: 'Sections.PersonalAccount',
          link: '/',
        },
        {
          titleKey: 'Sections.TripsTasks',
          link: '/trips-tasks',
        },
      ],
    },
  },
  {
    path: '/trips-requests/business-trip-with-conf/',
    redirect: { name: 'Trips requests' },
  },
  {
    path: '/trips-requests/business-trip/',
    redirect: { name: 'Trips requests' },
  },
  {
    path: '/trips-requests/conferences/',
    redirect: { name: 'Trips requests' },
  },
];
export default tripsRoutes;
